.providers {
  margin-top: 10px;
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  font-family: 'Lato', sans-serif;
  color: #707070;
}

.provider {
  flex: 2;
  width: 48%;
  margin-bottom: 20px;
  align-items: center;
  font-size: 18px;
}

.circle {
  border-radius: 100%;
}

.circle img {
  width: 170px;
  height: 170px;
  object-fit: cover;
  border-radius: 100%;
  border: 3px solid #D6B260;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-weight: normal;
  color: #707070;
}

.middle img{
  width: 280px;
  height: 280px;
  border-radius: 100%;
  border: 3px solid #D6B260;
}

.confirm {
  margin-top: 5rem;
  align-self: center;
  width: 200px;
  height: 70px;
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  background-color: #627985;
  color: #fff;
  border: none;
}

.name {
  margin-top: 1rem;
  font-family: 'Lato', sans-serif;
}

.confirmedName {
  font-size: 30px;
  font-family: 'Playfair Display', serif;
}